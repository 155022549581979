<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style='width:200px' placeholder="标题"></el-input>
			</div>
			<!-- 类型 -->
			<div class="filter-item">
				<label class="label">类型: </label>
				<el-select v-model="informationType" placeholder="请选择">
					<el-option v-for="item in typeOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 类型 -->
			<div class="filter-item">
				<label class="label">分组: </label>
				<!-- <el-select v-model="informationGroupId" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in groupOptions"	:key="item.Id" :label="item.GroupName"	:value="item.Id">
					</el-option>
				</el-select> -->
				<el-cascader v-model="informationGroupId" :options="groupOptions" style="width:220px" filterable
             :props="{value:'Id',label:'GroupName',children:'LowerList'}"></el-cascader>
			</div>
			<!-- 类型 -->
			<div class="filter-item">
				<label class="label">是否显示: </label>
				<el-select v-model="isShow" placeholder="请选择">
					<el-option v-for="item in isShowOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<buttonPermissions datas="marketcontentEdit" style="margin-left:30px;">
					<el-button type="primary" @click="handleTableEdit({})">添加</el-button> 
				</buttonPermissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData"	style="width: 100%" v-loading='loading'>
				<el-table-column	label="标题" width="270px">
					<template slot-scope="scope">
            <div class="mul-line-overflow">{{scope.row.Title}}</div>
					</template>
				</el-table-column>
				<el-table-column  prop="InformationTypeValue"	label="类型"></el-table-column>
				<el-table-column	prop="GroupNameShow"	label="分组"></el-table-column>
				<el-table-column	prop="ActivityState"	label="序号" min-width="120">
					<template slot-scope="scope">
					  <el-input v-model="scope.row.Sort" :disabled='!scope.row.isShowEdit' @input='scope.row.Sort=validateInt(scope.row.Sort)'  style="width:150px;margin-right: 5px;"></el-input>
            <i class="el-icon-edit-outline" @click="scope.row.isShowEdit = true" v-if="!scope.row.isShowEdit"></i>
            <template v-if="scope.row.isShowEdit">
              <el-button type="text" @click="saveEditSort(scope.row)">确定</el-button>
              <el-button type="text" @click="cancelEditSort(scope.row)" style="margin-left: 5px;color:#F56C6C" >取消</el-button>
            </template>
          </template>
				</el-table-column>
				<el-table-column label="是否显示">
					<template slot-scope="scope">
						<el-switch 
							v-model="scope.row.IsDisplay" 
							@change="changeIsDisplay(scope.row)"
						></el-switch>
					</template>
        </el-table-column>
				<el-table-column	prop="AddTime"	label="创建时间">	</el-table-column>
				<el-table-column	label="操作" width="200px">
					<template slot-scope="scope">
						<div class="flex flex-align-center">
							<buttonPermissions datas="marketcontentEdit" class="margin-right-10">
								<div class="table-button" @click="handleTableEdit(scope.row)" >编辑</div>
							</buttonPermissions>
							<buttonPermissions datas="marketcontentDel" class="margin-right-10">
              <div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>	
import {
	informationpageinit,
  editinformationsort,
  editinformationisdisplay,
  informationdelete,
	informationgrouppageinit,
} from '@/api/goods.js'
import {
  productAttributeproductAttributeTree,
	informationinformationGroupTree
} from "@/api/TurnTomySelf"
	import buttonPermissions from '@/components/buttonPermissions';
import {
	validateInt
} from '@/utils/validate';
	export default {
		components:{
			buttonPermissions
		},
		data(){
			return{
				loading:false,
				statusType:['未开始','进行中','已结束'],
				searchKey:'',
				informationGroupId: null,
				groupOptions: [],
				informationType: null,
				typeOptions:[
					{
						label:'全部',
						value:null
					},
					{
						label:'图片',
						value:1,
					},
					{
						label:'视频',
						value:2
					},
					{
						label:'图文',
						value:3
					}
				],
				isShow:null,
				isShowOptions:[
					{
						label:'全部',
						value:null
					},
					{
						label:'是',
						value: true,
					},
					{
						label:'否',
						value: false
					}
				],
				tableData:[],
				page:{
					total:0,
					current:1,
					size:20
				},
			}
		},
		mounted (){
			this.getshop()
			this.getList()
		},
    methods: {
				// 获取列表数据
			// async getGroupList() {
			// 	let data = {
			// 		Keywords : '', // 搜索关键字
			// 		Skip: 0, 
			// 		Take: 9999999, 
			// 		IsAsc : false, 
			// 	}
			// 	let result = await informationgrouppageinit(data);

			// 	this.groupOptions = result.Result.Results
			// },
			async getshop(){
				try{
					let res = await informationinformationGroupTree({
						Keywords:'',
						Skip:0,
						Take:99999
					})
					if(res.IsSuccess){
						this.groupOptions = [{
							Id:null,
							GroupName:'全部'
						},...res.Result].map(v=>{
							if(!v.LowerList||!v.LowerList.length){
								delete v.LowerList
							}
							return v
						})
					}
				}finally{
					//
				}
			},
			validateInt(num) {
        if(num==0) {
          return ''
        }
				return validateInt(num)
			},
				// 获取列表数据
			async getList() {
				this.loading = true;
				let id = this.informationGroupId
				if(this.informationGroupId&&this.informationGroupId.length){
					id = this.informationGroupId[this.informationGroupId.length-1]
				}
				try {
					let data = {
						Keywords : this.searchKey, // 搜索关键字
						InformationType: this.informationType,
						InformationGroupId: id,
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
						IsDisplay :this.isShow
					}
					let result = await informationpageinit(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results.map(t=> {
            return {
              ...t,
              isShowEdit:  false,
            }
          })

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			
      async saveEditSort(val) {
        if(!val.Sort) {
          this.$message({
            showClose: true,
            type: 'error',
            message: '请输入正整数'
          });
          return false
        }
	 			try { 
					let data = {
						Id : val.Id,
            Sort : val.Sort,
					}
					await editinformationsort(data);
          
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          });
			    this.getList()

				} catch (error) {
				}
      },
      cancelEditSort() {
        this.getList()
      },
			
			async changeIsDisplay(val) {
				try { 
					let data = {
						Id : val.Id,
            IsDisplay : val.IsDisplay,
					}
					await editinformationisdisplay(data);
          
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          });
			    this.getList()

				} catch (error) {
				}

      },
			handleFilter(){
				this.page.current = 1
				this.getList()
			},
			// table编辑
			handleTableEdit(val){
				this.$router.push({ 
          path: '/market/message/addMessage',
          query:{
            id:val.Id || null
          } 
        })
			},
			// table删除
			handleTableDelete(id){
				this.$confirm('确定是否删除当前信息，删除后不可恢复！', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					try {
						await informationdelete({
							Id: id
						})
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					} catch (e) {
						return false
					} 

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
    }
	}
</script>

<style  lang="less" scoped>
.app-container{
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}
	.mul-line-overflow {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		width: 250px;
	} 
	.dialog{
		width: 100%;
		::v-deep .el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
	}

</style>
