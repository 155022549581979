<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="内容管理" name="1"></el-tab-pane>
			<el-tab-pane label="内容分组" name="2"></el-tab-pane>
		</el-tabs>
		<div v-if="activeName=='1'">
			<marketcontent key="marketcontent"></marketcontent>
		</div>
		<div v-else>
			<groupList key="groupList"></groupList>
		</div>
	</div>
</template>

<script>	
	import marketcontent from "./marketcontent"
	import groupList from "./groupList"
	export default {
		components:{
			marketcontent,
			groupList
		},
		data(){
			return{
				activeName:'1'
			}
		},
		mounted () {
			if(window.localStorage.getItem('manageListActiveName')){
				this.activeName = window.localStorage.getItem('manageListActiveName')
			}
			window.localStorage.removeItem('manageListActiveName')
			if(this.$route.params.activeName){
				this.activeName = this.$route.params.activeName
			}
		},
		methods: {
			handleClick(e){

			}
		}
	}
</script>

<style  lang="less" scoped>

.app-container{
	background-color: #fff;
}
</style>
