<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" style='width:200px' placeholder="分组名称"></el-input>
			</div>
			<!-- 类型 -->
			<div class="filter-item">
				<label class="label">是否显示: </label>
				<el-select v-model="isDisplay" placeholder="请选择">
					<el-option v-for="item in displayOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<buttonPermissions datas="groupListEdit" style="margin-left:30px;width:100px;">
        	<el-button type="primary" @click="handleTableEdit({},1)">添加</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container" v-loading='loading'>
			<!-- <el-table v-if="loading">
				<el-table-column	prop=""	label="分组名称" key="tab0-1">

				</el-table-column>
				<el-table-column	prop=""	label="内容数量" key="tab0-2"></el-table-column>
				<el-table-column	prop=""	label="序号" key="tab0-3"></el-table-column>
				<el-table-column	prop=""	label="是否显示" key="tab0-4"></el-table-column>
				<el-table-column	prop=""	label="创建时间" key="tab0-5"></el-table-column>
				<el-table-column	prop=""	label="操作" key="tab0-6"></el-table-column>
			</el-table> -->
			<div>
				<el-table	:data="tableData"	style="width: 100%" row-key="Id" empty-text="查询不到相关分组" key="tab1"
				:row-class-name="rowClassName" :tree-props="{children: 'LowerList', hasChildren:true}" ref="table">
					<el-table-column	prop="GroupName"	label="分组名称" key="tab1-1">
						<template slot-scope="scope">
							<div class="ellipsis">
								{{scope.row.GroupName}}
							</div>
						</template>
					</el-table-column>
					<el-table-column	prop="InformationCount"	label="内容数量" key="tab1-2"></el-table-column>
					<el-table-column	prop="ActivityState"	label="序号" key="tab1-3" min-width="120">
						<template slot-scope="scope">
							<div v-if="scope.row.level==2">
								{{scope.row.Sort}}
							</div>
							<div v-else>
								<el-input v-model="scope.row.Sort" :disabled='!scope.row.isShowEdit' @input='scope.row.Sort=validateInt(scope.row.Sort)'  style="width:150px;margin-right: 5px;"></el-input>
								<i class="el-icon-edit-outline" @click="scope.row.isShowEdit = true" v-if="!scope.row.isShowEdit"></i>
								<div v-if="scope.row.isShowEdit">
									<el-button type="text" @click="saveEditSort(scope.row)">确定</el-button>
									<el-button type="text" @click="cancelEditSort(scope.row)" style="margin-left: 5px;color:#F56C6C" >取消</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column	label="是否显示" key="tab1-4">
						<template slot-scope="scope">
							<el-switch 
								v-model="scope.row.IsDisplay" 
								@change="changeIsDisplay(scope.row)"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column	prop="AddTime"	label="创建时间" key="tab1-5">	</el-table-column>
					<el-table-column	label="操作" width="220px" key="tab1-6">
						<template slot-scope="scope">
							<div class="flex flex-align-center">
								<buttonPermissions datas="groupListEdit" class="margin-right-10" v-if="scope.row.level==1">
									<div class="table-button" @click="handleTableEdit(scope.row,3)">添加子分组</div>
								</buttonPermissions>
								<buttonPermissions datas="groupListEdit" class="margin-right-10">
									<div class="table-button" @click="handleTableEdit(scope.row,2)" >编辑</div>
								</buttonPermissions>
								<buttonPermissions datas="groupListDel" v-if="!scope.row.LowerList||!scope.row.LowerList.length">
									<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
								</buttonPermissions>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- <el-table	:data="tableData2"	style="width: 100%" row-key="Id" empty-text="查询不到相关分组" v-else key="tab2"
					:tree-props="{children: 'LowerList',hasChildren: true}" :row-class-name="rowClassName" default-expand-all>
					<el-table-column	prop="GroupName"	label="分组名称" key="tab2-1"></el-table-column>
					<el-table-column	prop="InformationCount"	label="内容数量" key="tab2-2"></el-table-column>
					<el-table-column	prop="ActivityState"	label="序号" key="tab2-3" min-width="120">
						<template slot-scope="scope">
							<div v-if="scope.row.level==2">
								{{scope.row.Sort}}
							</div>
							<div v-else>
								<el-input v-model="scope.row.Sort" :disabled='!scope.row.isShowEdit' @input='scope.row.Sort=validateInt(scope.row.Sort)'  style="width:150px;margin-right: 5px;"></el-input>
								<i class="el-icon-edit-outline" @click="scope.row.isShowEdit = true" v-if="!scope.row.isShowEdit"></i>
								<div v-if="scope.row.isShowEdit">
									<el-button type="text" @click="saveEditSort(scope.row)">确定</el-button>
									<el-button type="text" @click="cancelEditSort(scope.row)" style="margin-left: 5px;color:#F56C6C" >取消</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column	label="是否显示" key="tab2-4">
						<template slot-scope="scope">
							<el-switch 
								v-model="scope.row.IsDisplay" 
								@change="changeIsDisplay(scope.row)"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column	prop="AddTime"	label="创建时间" key="tab2-5">	</el-table-column>
					<el-table-column	label="操作" width="220px" key="tab2-6">
						<template slot-scope="scope">
							<div class="flex flex-align-center">
								<buttonPermissions datas="groupListEdit" class="margin-right-10" v-if="scope.row.level==1">
									<div class="table-button" @click="handleTableEdit(scope.row,3)">添加子分组</div>
								</buttonPermissions>
								<buttonPermissions datas="groupListEdit" class="margin-right-10">
									<div class="table-button" @click="handleTableEdit(scope.row,2)" >编辑</div>
								</buttonPermissions>
								<buttonPermissions datas="groupListDel" v-if="scope.row.level==2">
									<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
								</buttonPermissions>
							</div>
						</template>
					</el-table-column>
				</el-table> -->
			</div>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

		<!-- 添加分组 -->
		<el-dialog :title="editType==1?'添加分组':(editType==2?'编辑分组':'添加子分组')" :visible.sync="editVisible" width="600px" class="dialog">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">

				<el-form-item label="分组名称:" prop="GroupName" >
					<el-input v-model="ruleForm.GroupName" placeholder="最多输入16个字" maxlength="16" style="width:300px;"></el-input>
				</el-form-item>

				<!-- <el-form-item label="序号:" prop="Sort" >
					<el-input v-model="ruleForm.Sort" @input='ruleForm.Sort=validateInt(ruleForm.Sort)'  style="width:100px;"></el-input>
				</el-form-item>

				<el-form-item label="是否显示:" prop="IsDisplay" >
					<el-radio-group v-model="ruleForm.IsDisplay">
						<el-radio :label="true">是</el-radio>
						<el-radio :label="false">否</el-radio>
					</el-radio-group>
				</el-form-item> -->

				<div style="text-align:right;margin-top:20px;">
					<el-button style="margin-left:auto" @click="handleCloseGroup">关闭</el-button>
					<el-button @click="handleSaveGroup('ruleForm')" type="primary" :loading="btnloading">保存</el-button>
				</div>

			</el-form>
		</el-dialog>
	</div>
</template>

<script>	
import {
	informationgrouppageinit,
  informationgroupedit,
  editinformationgroupsort,
  editinformationgroupisdisplay,
  informationgroupdelete,
	informationinformationgrouppageinitnew
} from '@/api/goods.js'
	import {
		validateInt
	} from '@/utils/validate';
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data(){
			return{
				loading:false,
				searchKey:'',
				isDisplay:null,
				displayOptions:[
					{
						label:'全部',
						value: null
					},
					{
						label:'是',
						value:true,
					},
					{
						label:'否',
						value:false
					}
				],
				tableData:[],
				tableData2:[],
				page:{
					total:0,
					current:1,
					size:20
				},

        editVisible: false,
				ruleForm: {
					Id: 0,
					GroupName: '',
					Sort: 1,
					IsDisplay: true,
				},
				rules: {
					GroupName: [{
						required: true,
						message: '请输入分组名称',
						trigger: 'blur'
					}],
					Sort: [{
						required: true,
						message: '请输入序号',
						trigger: 'blur'
					}],
					IsDisplay: [{
						required: true,
						message: '请选择是否显示',
						trigger: 'change'
					}],
				},

				editType:1,
				btnloading:false,
				hassearch:false
			}
		},
		mounted (){
			this.getList()
		},
    methods: {
			resetLazyTree() {
				this.$set(this.$refs.table.store.states, 'lazyTreeNodeMap', {})
			},
			async getchildrenlist(tree, treeNode, resolve){
				try{
					let res = await informationgrouppageinit({
						Keywords : this.searchKey, // 搜索关键字
						IsDisplay: this.isDisplay,
						Skip:0, 
						Take: 99999, 
						ParentId:tree.Id
					})
					if(res.IsSuccess){
						resolve(res.Result.Results.map(v=>{
							v.level = 2
							return v
						}))
					}
				}finally{
					//
				}
			},
			rowClassName({row}){
				if(row.level==2){
					return 'undertone'//最低层级添加底色
				}else{
					return 'replace'//有下级的替换i标签的内容
				}
			},
			validateInt(num) {
        if(num==0) {
          return ''
        }
				return validateInt(num)
			},
				// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						Keywords : this.searchKey, // 搜索关键字
						IsDisplay: this.isDisplay,
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
					}
					let result = await informationinformationgrouppageinitnew(data);
					if(result.IsSuccess){
						let tableData = result.Result.Results.map(t=> {
							t.isShowEdit = false
							t.level = 1
							t.show = this.hassearch
							t.complete = this.hassearch
							t.hasChildren = true
							if(t.LowerList&&t.LowerList.length){
								t.LowerList = t.LowerList.map(v=>{
									v.level = 2
									return v
								})
							}else{
								t.LowerList = []
							}
							return t
						})
						this.page.total = result.Result.Total;
						// if(!this.hassearch){
						// 	this.tableData2 = []
							this.tableData = tableData
						// }else{
						// 	this.tableData = []
						// 	this.tableData2 = tableData
						// }
						// this.tableData = tableData
						// console.log(this.tableData,this.tableData2)
					}
				} finally {
					this.loading = false;
				}
			},
      async saveEditSort(val) {
        if(!val.Sort) {
          this.$message({
            showClose: true,
            type: 'error',
            message: '请输入正整数'
          });
          return false
        }
	 			try { 
					let data = {
						Id : val.Id,
            Sort : val.Sort,
					}
					await editinformationgroupsort(data);
          
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          });
			    this.getList()

				} catch (error) {
				}
      },
      cancelEditSort() {
        this.getList()
      },
			async changeIsDisplay(val) {
				try { 
					let data = {
						Id : val.Id,
            IsDisplay : val.IsDisplay,
					}
					await editinformationgroupisdisplay(data);
          
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          });
			    // this.getList()

				} catch (error) {
				}

      },
			handleFilter(){
        this.page.current = 1
				this.getList()
				this.hassearch = Boolean(this.searchKey)
			},
			// table编辑
			handleTableEdit(val,type){
				this.editType = type
				this.editVisible = true
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
				if(type==1){
					this.ruleForm = {
						Id: 0,
            GroupName: '',
            Sort: 1,
            IsDisplay: true,
						ParentId:0,
					}
				}else{
					this.ruleForm = JSON.parse(JSON.stringify(val))
					// console.log(this.ruleForm)
					if(type==3){
						this.ruleForm.ParentId = this.ruleForm.Id
						this.ruleForm.Id = 0
						this.ruleForm.GroupName = ''
					}
				}
				
			},
      
			
			//关闭edit
			handleCloseGroup() {
				this.editVisible = false
			},
			//保存edit
			async handleSaveGroup(formName) {
				// this.$refs[formName].validate(async(valid) => {
				// 	if (valid) {
					if(!this.ruleForm.GroupName){
						this.$message.error('请输入分组名称')
						return
					}
						try {
							this.btnloading = true
							let result = await informationgroupedit(this.ruleForm)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功!'
								});
								this.resetLazyTree()
								this.editVisible = false
								if(this.editType==1){
									this.getList()
								}else if(this.editType==2){
									this.tableData = this.updatatablelist(this.tableData,'GroupName',this.ruleForm)
								}else {
									// console.log(result.Result,this.ruleForm)
									this.tableData = this.tableData.map(v=>{
										if(v.Id==this.ruleForm.ParentId){
											v.LowerList = [{
												...result.Result,
												GroupName:this.ruleForm.GroupName,
												InformationCount:0,
												Sort:0,
												ParentId:this.ruleForm.ParentId,
												level:2,
												IsDisplay:true
											},...v.LowerList]
										}
										return v
									})
								}

							}
						} catch (e) {
							console.log(e)
						} finally {
							this.btnloading = false
						}

			},
			updatatablelist(list,key,msg){
				return list.map(v=>{
					if(v.Id==msg.Id){
						v[key] = msg[key]
					}else{
						if(v.LowerList&&v.LowerList.length){
							v.LowerList = this.updatatablelist(v.LowerList,key,msg)
						}
					}
					return v
				})
			},
			// table删除
			handleTableDelete(id){
				this.$confirm('确定是否删除当前分组，删除后不可恢复！', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					try {
						await informationgroupdelete({
							Id: id
						})
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						// this.getList()
						this.resetLazyTree()
						this.tableData = this.refreshdata(this.tableData,id)
					} catch (e) {
						return false
					} 

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
			refreshdata(list,Id){
				return list.filter(v=>{
					if(v.Id==Id){
						// console.log(v)
						return false
					}else{
						if(v.LowerList&&v.LowerList.length){
							v.LowerList = this.refreshdata(v.LowerList,Id)
						}
						return v
					}
				}).map(v=>{
					if(!v.LowerList||!v.LowerList.length){
						v.IsLowest = true
					}
					return v
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
    }
	}
</script>

<style  lang="less" scoped>


::v-deep .undertone.el-table__row{
	.cell{
    display: flex;
    align-items: center;
    .el-table__expand-icon{
      margin-top: 4px;
    }
  }
	background: #FBFBFBFF;
	.el-table__expand-icon{
			pointer-events: none;
		i{
			display: none;
			content:'' !important;
			width: 14px;
			height: 14px;
		}
		.el-icon-loading{
			animation:none;
		}
	}
	.el-table__expand-icon--expanded{
		transform:none;
			pointer-events: none;
		i{
			display: none;
			content:'' !important;
			width: 14px;
			height: 14px;
		}
	}
}
::v-deep .replace{
	.cell{
    display: flex;
    align-items: center;
    .el-table__expand-icon{
      margin-top: 4px;
    }
  }
	.el-table__expand-icon{
		i{
			display: inline;
			content:url('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/groupList-add.png') !important;
			width: 14px;
			height: 14px;
		}
		.el-icon-loading{
			animation:none;
		}
	}
	.el-table__expand-icon--expanded{
		transform:none;
		i{
			display: inline;
			content:url('http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/groupList-subtract.png') !important;
			width: 14px;
			height: 14px;
		}
	}
}
.app-container{
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		// margin-right: 20px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		::v-deep .el-dialog__header {
				// border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
	}

</style>
